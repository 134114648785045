import { apiGet, apiPost, generateFormData } from '../../modules/apiHelper';
import resolve from '../../modules/api/resolve';

export default {
  async getPackages() {
    // do not cache
    const request = apiGet('pack_list', 15, undefined, true, false)
      .catch((err) => {
        console.warn('getPackages::pack_list err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (res.data.error) return false;

        // console.log(res.data);

        return res.data.pack_list;
      });
    //

    // wait for the xhr
    const result = await request;
    return result;
  },
  async savePackage(context, data) {
    const createPackage = async () => {
      const formData = generateFormData({
        name: data.name,
        description: data.description,
        amount: data.amount,
        duration: data.duration,
        pillarid: data.pillarid,
      });

      const packageReq = await resolve(apiPost('create_package', formData, 15));

      return packageReq;
    };

    const updatePackage = async () => {
      const packNameReq = resolve(apiPost(
        'edit_pack_name',
        generateFormData({
          packid: data.id,
          package_name: data.name,
        }),
        15,
      ));

      const packDescReq = resolve(apiPost(
        'edit_pack_desc',
        generateFormData({
          packid: data.id,
          desc: data.description,
        }),
        15,
      ));

      const packAmountReq = resolve(apiPost(
        'edit_pack_amount',
        generateFormData({
          packid: data.id,
          amount: data.amount,
        }),
        15,
      ));

      const packDurationReq = resolve(apiPost(
        'edit_duration',
        generateFormData({
          packid: data.id,
          duration: data.duration,
        }),
        15,
      ));

      const packPillarReq = resolve(apiPost(
        'edit_pillar',
        generateFormData({
          packid: data.id,
          pillarid: data.pillarid,
        }),
        15,
      ));

      const result = await Promise.all([
        packNameReq,
        packDescReq,
        packAmountReq,
        packDurationReq,
        packPillarReq,
      ]);

      console.log('savePackage::updatePackage', result);

      return result.every((r) => !!r);
    };

    let result;

    if (data.id) {
      // run the different apis for updating the class
      result = await updatePackage();
    } else {
      // create
      result = await createPackage();
    }

    return result;
  },
};
